











import {
  defineComponent,
  useRoute,
  ssrRef,
  useContext,
  useFetch,
} from '@nuxtjs/composition-api';
import { SfSkeleton } from '@storefront-ui/vue';
import { CacheTagPrefix, useCache } from '@gemini/vsf-cache';
import { useUrlResolver } from '~/composables/useUrlResolver';
import cacheControl from '~/helpers/cacheControl';
import { useCms } from '~/composables';

interface RootComponent {
  name: string;
  props?: object;
}

function isRedirect(redirectCode: number | undefined): boolean {
  const redirectCodes = new Set().add(301).add(302);
  return redirectCodes.has(redirectCode);
}

import { isCmsPage } from '~/helpers/cms/cmsData';

export default defineComponent({
  name: 'RoutePage',
  components: {
    SfSkeleton,
    ProductPage: () => import('~/pages/Product.vue'),
    CategoryPage: () => import('~/pages/Category.vue'),
    CmsPage: () => import('~/pages/Page.vue'),
    PayloadPage: () => import('~/pages/PayloadPage.vue'),
  },
  middleware: cacheControl({
    'max-age': 60,
    'stale-when-revalidate': 5,
  }),
  transition: 'fade',
  setup() {
    const CacheTagPrefixUrl = 'U';
    const { error: nuxtError, redirect, app } = useContext();
    const route = useRoute();
    const {
      params: { path },
      path: routePath,
    } = route.value;
    const {
      result,
      search: resolveUrl,
      loading: urlResolverLoading,
    } = useUrlResolver();
    const rootComponent = ssrRef<RootComponent | null>(null);
    const { addTags } = useCache();
    const { cmsData, getCmsPage } = useCms();

    useFetch(async () => {
      rootComponent.value = { name: '' };
      await resolveUrl();

      const component: RootComponent = { name: '' };
      const urlRewrite = result?.value;
      const shouldRedirect = isRedirect(urlRewrite?.redirectCode);

      if (
        shouldRedirect &&
        urlRewrite?.urlPath &&
        urlRewrite?.urlPath !== path
      ) {
        const regx = /^(?:[a-z]+:)?\/\//i;
        const redirectPath = regx.test(urlRewrite.urlPath)
          ? urlRewrite.urlPath
          : app.localePath(`/${urlRewrite.urlPath.replace(/^(\/)/, '')}`);
        redirect(urlRewrite.redirectCode, redirectPath);
        return;
      }

      switch (urlRewrite?.entityCode) {
        case 'Product': {
          component.name = 'ProductPage';
          component.props = {
            productId: urlRewrite?.entityId,
          };
          break;
        }
        case 'ProductList': {
          component.name = 'CategoryPage';
          component.props = {
            categoryId: urlRewrite?.entityId,
          };
          break;
        }
        case null: {
          await getCmsPage(urlRewrite.entityId);
          if (
            cmsData?.value &&
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            cmsData?.value?.layout?.length > 0 &&
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            cmsData.value._status !== 'draft'
          ) {
            component.name = 'PayloadPage';
            component.props = {
              contentPage: cmsData?.value,
            };
          }
          break;
        }
        default: {
          if (isCmsPage(path)) {
            component.name = 'CmsPage';
            component.props = {
              identifier: routePath,
            };
          } else {
            component.name = 'Error';
            component.props = {
              identifier: path,
            };
          }
          break;
        }
      }

      if (!component?.name || component.name === 'Error') {
        nuxtError({ statusCode: 404 });
      } else {
        if (component.name !== 'CmsPage') {
          addTags([
            { prefix: CacheTagPrefix.View, value: 'urlrewrite' },
            { prefix: CacheTagPrefixUrl, value: urlRewrite?.entityId },
          ]);
        }
      }
      rootComponent.value = component;
    });

    return {
      rootComponent,
      loading: urlResolverLoading,
    };
  },
});
